import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

const CostSummary = ({ totalEstimatedCost, totalActualCost, summary, recordCount }) => {
  if (!summary) {
    return null;
  }

  return (
    <div className="p-4 border border-gray-300 rounded-md">
      <h2 className="text-xl font-bold mb-2">Summary</h2>
      <div className="flex justify-between items-center mb-2">
        <strong>Account Name:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">{summary.account_name}</span>
          <CopyToClipboard text={summary.account_name}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>Project Name:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">{summary.project_name}</span>
          <CopyToClipboard text={summary.project_name}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>Start Date:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">{summary.start_date}</span>
          <CopyToClipboard text={summary.start_date}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>End Date:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">{summary.end_date}</span>
          <CopyToClipboard text={summary.end_date}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>Total Estimated Cost:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">${totalEstimatedCost.toFixed(2)}</span>
          <CopyToClipboard text={totalEstimatedCost.toFixed(2)}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>Total Actual Cost:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">${totalActualCost.toFixed(2)}</span>
          <CopyToClipboard text={totalActualCost.toFixed(2)}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2">
        <strong>Record Count:</strong>
        <div className="flex items-center">
          <span className="text-right mr-2">{recordCount}</span>
          <CopyToClipboard text={recordCount}>
            <FaCopy className="cursor-pointer text-gray-500 hover:text-gray-700" />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default CostSummary;
