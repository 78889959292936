import React, { useEffect, useCallback } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import config from '../config';

const userPool = new CognitoUserPool({
  UserPoolId: config.userPoolId,
  ClientId: config.userPoolClientId,
});

const AuthHandler = ({
  username, setUsername,
  password, setPassword,
  message, setMessage,
  setIsLoggedIn,
  resetFormData,
  children
}) => {
  useEffect(() => {
    let timer;
    if (message === 'Login successful') {
      timer = setTimeout(() => setMessage(''), 3000);
    }
    return () => clearTimeout(timer);
  }, [message, setMessage]);

  const refreshCredentials = useCallback(async (session) => {
    const logins = {
      [`cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`]: session.getIdToken().getJwtToken(),
    };

    const client = new CognitoIdentityClient({
      region: config.region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: config.region }),
        identityPoolId: config.identityPoolId,
        logins: logins,
      }),
    });

    try {
      await client.config.credentials();
      console.log('AWS credentials refreshed successfully');
    } catch (err) {
      console.error('Error refreshing AWS credentials:', err);
      throw err;
    }
  }, []);

  useEffect(() => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          console.error('Error getting session:', err);
          setMessage('Session expired, please login again.');
          setIsLoggedIn(false);
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('username');
          localStorage.removeItem('accessToken');
        } else {
          try {
            console.log('Session retrieved successfully:', session);
            await refreshCredentials(session);
            setMessage('Credentials refreshed');
            setIsLoggedIn(true);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('username', currentUser.getUsername());  // Store username
            localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());  // Store access token
            setUsername(currentUser.getUsername());
          } catch (error) {
            console.error('Error refreshing credentials:', error);
            setMessage('Error refreshing credentials, please login again.');
            setIsLoggedIn(false);
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('username');
            localStorage.removeItem('accessToken');
          }
        }
      });
    } else {
      const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
      const storedUsername = localStorage.getItem('username');
      const storedAccessToken = localStorage.getItem('accessToken');
      if (storedIsLoggedIn === 'true' && storedUsername && storedAccessToken) {
        setIsLoggedIn(true);
        setUsername(storedUsername);  // Retrieve stored username
      }
    }
  }, [refreshCredentials, setIsLoggedIn, setMessage, setUsername]);

  const handleLogin = (e) => {
    e.preventDefault();
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();  // Get the access token
        const logins = {
          [`cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`]: idToken
        };

        const client = new CognitoIdentityClient({
          region: config.region,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: config.region }),
            identityPoolId: config.identityPoolId,
            logins: logins,
          }),
        });

        try {
          await client.config.credentials();
          setMessage('Login successful');
          setIsLoggedIn(true);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('username', username);  // Store username
          localStorage.setItem('accessToken', accessToken);  // Store access token
          resetFormData();
        } catch (error) {
          console.error('Error setting credentials:', error);
          setMessage('Error logging in, please try again.');
        }
      },
      onFailure: (err) => {
        console.error('Error authenticating user:', err);
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const handleLogout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');  // Remove stored username
    localStorage.removeItem('accessToken');  // Remove stored access token
    resetFormData();
  };

  return (
    <div>
      {children({ handleLogin, handleLogout })}
    </div>
  );
};

export default AuthHandler;
