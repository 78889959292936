import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

const DataInputForm = ({
  jiraTicket,
  setJiraTicket,
  timePeriod,
  setTimePeriod,
  granularity,
  setGranularity,
  handleSubmit,
  data,
  showExportButton
}) => {
  const [jiraError, setJiraError] = useState('');
  const [timePeriodError, setTimePeriodError] = useState('');
  const csvFilename = `tag-cost-explorer-${jiraTicket || 'default'}-${new Date().toISOString()}.csv`;

  const validateJiraTicket = (ticket) => {
    if (!ticket.startsWith('NFI-')) {
      setJiraError('JIRA ticket must begin with "NFI-"');
      return false;
    }
    setJiraError('');
    return true;
  };

  const validateTimePeriod = (period) => {
    if (isNaN(period) || period < 7 || period > 90) {
      setTimePeriodError('Time Period must be a number between 7 and 90');
      return false;
    }
    setTimePeriodError('');
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isJiraValid = validateJiraTicket(jiraTicket);
    const isTimePeriodValid = validateTimePeriod(timePeriod);

    if (isJiraValid && isTimePeriodValid) {
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={onSubmit} className="mb-4">
      <div className="mb-4">
        <label className="block text-gray-700">JIRA Ticket</label>
        <input
          type="text"
          value={jiraTicket}
          onChange={(e) => setJiraTicket(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
        {jiraError && <p className="text-red-500 text-xs italic">{jiraError}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Time Period (days)</label>
        <input
          type="number"
          placeholder="Time Period between 7 and 90"
          value={timePeriod}
          onChange={(e) => setTimePeriod(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
        {timePeriodError && <p className="text-red-500 text-xs italic">{timePeriodError}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Granularity</label>
        <select
          value={granularity}
          onChange={(e) => setGranularity(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="MONTHLY">Monthly</option>
          <option value="DAILY">Daily</option>
        </select>
      </div>
      <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
      {showExportButton && (
        <CSVLink
          data={data}
          filename={csvFilename}
          className="bg-green-500 text-white py-2 px-4 rounded ml-3"
        >
          Export to CSV
        </CSVLink>
      )}
    </form>
  );
};

export default DataInputForm;
