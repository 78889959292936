import React from 'react';

const LoginForm = ({ username, setUsername, password, setPassword, handleLogin }) => (
  <form onSubmit={handleLogin} className="mb-4">
    <div className="mb-4">
      <label htmlFor="username" className="block text-black">Username</label>
      <input
        id="username"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        placeholder="Username"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
    </div>
    <div className="mb-4">
      <label htmlFor="password" className="block text-black">Password</label>
      <input
        id="password"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
    <button className="bg-gray-500 text-white py-2 px-4 rounded" type="submit">Login</button>
  </form>
);

export default LoginForm;
