/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import './tailwind.css'; // Import Tailwind CSS
import LoginForm from './components/LoginForm';
import DataInputForm from './components/DataInputForm';
import DataTable from './components/DataTable';
import AuthHandler from './components/AuthHandler';
import Message from './components/Message';
import Spinner from './components/Spinner';
import CostSummary from './components/CostSummary';
import config from './config';

const columns = [
  {
    Header: 'Resource ARN',
    accessor: 'resourceArn',
    Cell: ({ value }) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ),
  },
  {
    Header: 'Resource Type',
    accessor: 'resourceType',
  },
  {
    Header: 'Resource Name',
    accessor: 'resourceName',
  },
  {
    Header: 'Actual Cost',
    accessor: 'actualCost',
  },
  {
    Header: 'Amortized Cost',
    accessor: 'amortizedCost',
  },
  {
    Header: 'Net Unblended Cost',
    accessor: 'netUnblendedCost',
  },
  {
    Header: 'Estimated Cost',
    accessor: 'estimatedCost',
  },
];

function App() {
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [jiraTicket, setJiraTicket] = useState('NFI-');
  const [timePeriod, setTimePeriod] = useState('30');
  const [granularity, setGranularity] = useState('MONTHLY');
  const [isLoading, setIsLoading] = useState(false);
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0);
  const [totalActualCost, setTotalActualCost] = useState(0);
  const [summary, setSummary] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [linkPatterns, setLinkPatterns] = useState({});

  useEffect(() => {
    const fetchLinkPatterns = async () => {
      const response = await fetch('/linkPatterns.json');
      const data = await response.json();
      setLinkPatterns(data);
    };

    fetchLinkPatterns();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem('accessToken');  // Retrieve access token

      if (!accessToken) {
        throw new Error('No access token found, please login again.');
      }

      const apiResponse = await axios.post(
        `https://${config.invokeUrl}/tag-cost-explorer`,
        {
          jira_ticket: jiraTicket,
          timedelta: timePeriod,
          granularity: granularity,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      const { costs, summary } = apiResponse.data;

      if (costs.length === 0) {
        throw new Error('No results found for the given Jira ticket.');
      }

      setResponse(apiResponse.data);
      setSummary(summary);
      setMessage('');  // Clear the error message on successful response
      setRecordCount(costs.length);

      const totalEstimated = costs.reduce((acc, curr) => acc + (parseFloat(curr.estimatedCost) || 0), 0);
      const totalActual = costs.reduce((acc, curr) => acc + (parseFloat(curr.actualCost) || 0), 0);

      setTotalEstimatedCost(totalEstimated);
      setTotalActualCost(totalActual);
    } catch (error) {
      setMessage(error.message);
      setResponse(null);
      setSummary(null);
      setTotalEstimatedCost(0);
      setTotalActualCost(0);
      setRecordCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormData = () => {
    setJiraTicket('NFI-');
    setTimePeriod('');
    setGranularity('MONTHLY');
    setResponse(null);
    setTotalEstimatedCost(0);
    setTotalActualCost(0);
    setSummary(null);
    setMessage('');
    setRecordCount(0);
  };

  const data = useMemo(() => response?.costs || [], [response]);

  return (
    <div className="App container mx-auto p-4">
      <div className="flex justify-between items-center mb-4 p-4 gradient-background rounded shadow">
        <h1 className="text-3xl text-white font-bold">Tag Cost Explorer</h1>
        <AuthHandler
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          message={message}
          setMessage={setMessage}
          setIsLoggedIn={setIsLoggedIn}
          resetFormData={resetFormData}
        >
          {({ handleLogin, handleLogout }) => (
            <>
              {!isLoggedIn ? (
                <LoginForm
                  username={username}
                  setUsername={setUsername}
                  password={password}
                  setPassword={setPassword}
                  handleLogin={handleLogin}
                />
              ) : (
                <button onClick={handleLogout} className="bg-gray-500 text-white py-2 px-4 rounded">Logout</button>
              )}
            </>
          )}
        </AuthHandler>
      </div>
      <Message message={message} />

      {isLoggedIn && (
        <div className="flex">
          <div className="w-1/2 p-4 border border-gray-300 rounded-md">
            <DataInputForm
              jiraTicket={jiraTicket}
              setJiraTicket={setJiraTicket}
              timePeriod={timePeriod}
              setTimePeriod={setTimePeriod}
              granularity={granularity}
              setGranularity={setGranularity}
              handleSubmit={handleSubmit}
              data={data}
              showExportButton={!!response}
            />
          </div>
          <div className="w-1/2 p-4 border border-gray-300 rounded-md">
            <CostSummary
              totalEstimatedCost={totalEstimatedCost}
              totalActualCost={totalActualCost}
              summary={summary}
              recordCount={recordCount}
            />
          </div>
        </div>
      )}
      {isLoggedIn && response && (
        <div className="w-full p-4 border border-gray-300 rounded-md relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 pt-8">
              <Spinner className="h-16" />
            </div>
          )}
          {!isLoading && (
            <DataTable
              columns={columns}
              data={data}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;
