import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Spinner = ({ className = '' }) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-500" />
    </div>
  );
};

export default Spinner;
